import {
    ADMIN_ADD_NOTIFICATION,
    ADMIN_ADD_NOTIFICATION_SUCCESS,
    ADMIN_ADD_NOTIFICATION_ERROR,
    ADMIN_GET_NOTIFICATIONS,
    ADMIN_GET_NOTIFICATIONS_SUCCESS,
    ADMIN_GET_NOTIFICATIONS_ERROR,
    ADMIN_DELETE_NOTIFICATION,
    ADMIN_DELETE_NOTIFICATION_SUCCESS,
    ADMIN_DELETE_NOTIFICATION_ERROR,
    ADMIN_GET_FILE_LIST,
    ADMIN_GET_FILE_LIST_SUCCESS,
    ADMIN_GET_FILE_LIST_ERROR,
    ADMIN_GET_FILE,
    ADMIN_GET_FILE_ERROR,
    ADMIN_GET_FILE_SUCCESS,
    ADMIN_CREATE_BILLING_REPORT,
    ADMIN_CREATE_BILLING_REPORT_SUCCESS,
    ADMIN_CREATE_BILLING_REPORT_ERROR
} from '../action';

const INIT_STATE = {
    loading: false,
    notificationList: [],
    fileList: [],
    s3NextToken: null
};

const admin = (state=INIT_STATE, action) => {
    switch (action.type) {
        case ADMIN_ADD_NOTIFICATION:
            return {...state, loading: true};
        case ADMIN_ADD_NOTIFICATION_SUCCESS:
            return {...state, loading: false};
        case ADMIN_ADD_NOTIFICATION_ERROR:
            return {...state, loading: false};
        case ADMIN_GET_NOTIFICATIONS:
            return {...state, loading: true};
        case ADMIN_GET_NOTIFICATIONS_SUCCESS:
            return {...state, loading: false, notificationList: action.payload};
        case ADMIN_GET_NOTIFICATIONS_ERROR:
            return {...state, loading: false};
        case ADMIN_DELETE_NOTIFICATION:
            return {...state, loading: true};
        case ADMIN_DELETE_NOTIFICATION_SUCCESS:
            return {...state, loading: false};
        case ADMIN_DELETE_NOTIFICATION_ERROR:
            return {...state, loading: false};
        case ADMIN_GET_FILE_LIST:
            return {...state, loading: true};
        case ADMIN_GET_FILE_LIST_ERROR:
            return {...state, loading: false};
        case ADMIN_GET_FILE_LIST_SUCCESS:
            const fileList = action.payload.Files.filter(file => file.Key.split('/').length === 2).map(file => {
                return { Key: file.Key, LastModified: file.LastModified };
            });
            return {...state, loading: false, fileList: fileList, s3NextToken: action.payload.NextToken};
        case ADMIN_GET_FILE:
            return {...state, loading: true};
        case ADMIN_GET_FILE_ERROR:
            return {...state, loading: false};
        case ADMIN_GET_FILE_SUCCESS:
            return {...state, loading: false};
        case ADMIN_CREATE_BILLING_REPORT:
            return {...state, loading: true};
        case ADMIN_CREATE_BILLING_REPORT_SUCCESS:
            return {...state, loading: false};
        case ADMIN_CREATE_BILLING_REPORT_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};

export default admin;
import axios from "axios";
import {
    LOGIN_USER_PENDING,
    LOGOUT_USER,
    GET_OVERVIEWS,
    GET_OVERVIEWS_SUCCESS,
    TOGGLE_SIDEBAR,
    CLEAR_ERROR_MESSAGE,
    CLEAR_RESPONSE_MESSAGE,
    SET_ERROR_MESSAGE,
    SET_RESPONSE_MESSAGE,
    SET_INFO_MESSAGE,
    CLEAR_INFO_MESSAGE,
    LOGIN_USER,
    TOGGLE_PERMISSIONS,
    REQUEST_OVERVIEW,
    CLEAR_RESEARCH_RESULTS,
    clearSearchFields,
    TOGGLE_QUESTIONS,
    AGREE,
    AGREE_SUCCESSFUL,
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    GET_CONTRACT_1,
    GET_CONTRACT_2,
    SET_LOGIN_STATUS,
    RESET_RESEARCH_RESULTS
} from '../action';
import {
    baseUrl,
    apiToken,
    tokenName,
    tokenExpiringDate
} from '../../constants/defaultValues';
import { 
    parseErrorMessages,
    getToken
} from "../../constants/helpers";

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['x-api-key'] = apiToken;
axios.interceptors.request.use(req => {
    req.headers.Authorization = getToken();
    return req;
});

export const getLoginServiceStatus = () => async dispatch => {
    try{
        const res = await axios.get('/common/getLoginServiceStatus');
        dispatch({type: SET_LOGIN_STATUS, payload: res.data.message});
        //dispatch({type: SET_LOGIN_STATUS, payload: 0});
    } catch(error){
        dispatch({type: SET_LOGIN_STATUS, payload: 0});
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const getContract = (type) => async dispatch => {
    try{
        let res;
        if (type === 1){
            dispatch({type: GET_CONTRACT_1});
            res = await axios.post('/common/getContract1', {});
        } else {
            dispatch({type: GET_CONTRACT_2});
            res = await axios.post('/common/getContract2', {});
        }
        let a = document.createElement("a");
        a.href = res.data.message;
        a.target = '_blank';
        a.click();
    } catch(error){
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const getNotification = () => async dispatch => {
    try {
        const res = await axios.get('/common/getNotifications');
        dispatch({type: GET_NOTIFICATION, payload: res.data.message});
    } catch (error) {
        console.log('No notification found.');
        dispatch({type: GET_NOTIFICATION_ERROR});
    }
};

export const toggleQuestion = () => async dispatch => {
    dispatch({type: TOGGLE_QUESTIONS});
}

export const getOverviews = () => async dispatch => {
    try{
        dispatch({type: GET_OVERVIEWS});
        const reports = await axios.post('/exportService/getReports', {});
        dispatch({type: GET_OVERVIEWS_SUCCESS, payload: reports.data.message});
    } catch(error){
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const getDownloadLink = (filename) => async dispatch => {
    try{
        dispatch({type: REQUEST_OVERVIEW});
        const res = await axios.post('/exportService/getReport', {filename: filename});
        let a = document.createElement("a");
        a.href = res.data.message;
        a.target = '_blank';
        a.click();
    } catch(error){
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const loginUser = (formData, successToggle, errorToggle) => async dispatch => {
    const {username, password} = formData;
    try {
        dispatch({type: LOGIN_USER_PENDING});
        const res = await axios.post('/authService/login',{clientId:username,clientPw:password});
        const mess = res.data.message;
        localStorage.setItem(tokenName,mess.jwt);
        localStorage.setItem(tokenExpiringDate,Date.now() + 4*60*60*1000);
        const modifiedUsername = username.replace(/[^0-9]/g, '');
        dispatch({type: LOGIN_USER, payload: {permissions: mess.permissions, customer: modifiedUsername}});
        await clearSearchFields();
        dispatch({type: CLEAR_RESEARCH_RESULTS});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Login erfolgreich!'});
        const showAgrement = {
            contract: mess.contract,
            price: mess.price
        };
        successToggle(showAgrement);
    } catch (error) {
        if(error.response){
            const errorCode = error.response.data['message'].split('_')[0];
            if(errorCode === '002')
                errorToggle();   
        }
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const agreeWithPopup = (successCallback, errorCallback, agreeType) => async dispatch => {
    try{
        dispatch({type: AGREE});
        await axios.post('/authService/agreeWithPopup', { agreeType });
        dispatch({type: AGREE_SUCCESSFUL});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Bestätigung verarbeitet. Vielen Dank!'});
        successCallback();
    } catch (error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
        errorCallback();
    }
}

export const changePassword = (formData, callback) => async dispatch => {
    const {username, oldPassword, newPassword} = formData;
    try{
        await axios.post('/authService/changePW',{clientId: username, clientOldPw: oldPassword, clientNewPw: newPassword});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Passwort erfolgreich geändert.'});
        callback();
    } catch(error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const togglePermission = () => async dispatch => {
    dispatch({type: TOGGLE_PERMISSIONS});
};

export const logoutUser = () => async dispatch => {
    localStorage.clear();
    dispatch({type: LOGOUT_USER, payload: 'Auf Wiedersehen!'});
    dispatch({type: RESET_RESEARCH_RESULTS})
};

export const toggleSidebar = () => async dispatch => {
    dispatch({type: TOGGLE_SIDEBAR});
};

export const setErrorMessage = (msg) => async dispatch => {
    dispatch({type:SET_ERROR_MESSAGE, payload: msg})
};

export const setResponseMessage = (msg) => async dispatch => {
    dispatch({type:SET_RESPONSE_MESSAGE, payload: msg});
};

export const setInfoMessage = (msg) => async dispatch => {
    dispatch({type:SET_INFO_MESSAGE, payload: msg})
};

export const clearErrorMessage = () => async dispatch => {
    dispatch({type:CLEAR_ERROR_MESSAGE});
};

export const clearResponseMessage = () => async dispatch => {
    dispatch({type:CLEAR_RESPONSE_MESSAGE});
};

export const clearInfoMessage = () => async dispatch => {
    dispatch({type:CLEAR_INFO_MESSAGE});
};
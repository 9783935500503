import {combineReducers} from 'redux';
import {reducer as toastr} from 'react-redux-toastr';
import common from './common/reducer';
import requests from './requests/reducer';
import admin from './admin/reducer';

export default combineReducers({
    admin,
    common,
    requests,
    toastr
});
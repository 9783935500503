import axios from "axios";
import {
    INQUIRY,
    INQUIRY_SUCCESS,
    SAVE_SEARCH_FIELDS,
    CLEAR_SEARCH_FIELDS,
    CLEAR_INQUIRY,
    RESEARCH,
    RESEARCH_SUCCESS,
    GET_RESEARCH_RESULTS,
    GET_RESEARCH_RESULTS_SUCCESS,
    GET_RESEARCH_RESULTS_SUCCESS_V2,
    SET_RESPONSE_MESSAGE,
    SET_ERROR_MESSAGE,
    DELETE_ENTRY,
    DELETE_ENTRY_SUCCESS,
    CLEAR_RESEARCH_RESULTS,
    CANCEL_LOADING,
    DELETE_ENTRY_2,
    DELETE_ENTRY_2_SUCCESS,
    DELETE_ENTRY_2_ERROR,
    CLEAR_RESEARCH_FIELDS,
    SAVE_RESEARCH_FIELDS
} from '../action';

import {
    baseUrl,
    apiToken
} from '../../constants/defaultValues';
import {
    getToken,
    parseErrorMessages
} from '../../constants/helpers';

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['x-api-key'] = apiToken;
axios.interceptors.request.use(req => {
    req.headers.Authorization = getToken();
    return req;
});

export const deleteEntry = (data) => async dispatch => {
    try{
        dispatch({type: DELETE_ENTRY})
        await axios.post('/researchService/deleteRecordResearchResult',data);
        dispatch({type: DELETE_ENTRY_SUCCESS})
    } catch(error){
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)})
    }
}

export const deleteEntry2 = ({row, callback}) => async dispatch => {
    try{
        dispatch({type: DELETE_ENTRY_2, to_del: row});
        //await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
        await axios.post('/researchService/deleteRecordResearchResult',row);
        callback();
        dispatch({type: DELETE_ENTRY_2_SUCCESS, to_del: row});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Eintrag erfolgreich gelöscht.'});
    } catch(error){
        callback();
        dispatch({type: DELETE_ENTRY_2_ERROR});
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
    }
};

export const sendInquiry = (data) => async dispatch => {    
    try{
        dispatch({type: INQUIRY});
        let res = null;
        if (data.searchType==='both')
            res = await axios.post('/dsapiService/getBoth', data);
        else
            res = await axios.post('/dsapiService/getMovers', data);
        dispatch({type: INQUIRY_SUCCESS, payload: res.data.message});
    } catch(error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)})
    }
};

export const sendResearch = (data) => async dispatch => {
    try{
        dispatch({type: RESEARCH, payload: `${data.clientRef} übermittelt`});
        const res = await axios.post('/researchService/checkResearchRequest', data);
        await axios.post('/researchService/putMoversResearch',{
            ...data,
            record: res.data.message
        });
        dispatch({type: RESEARCH_SUCCESS, payload: `${data.Vorgangsbezeichnung} erfolgreich erstellt.`})
        dispatch({type: CLEAR_RESEARCH_RESULTS});
        dispatch({type: CLEAR_RESEARCH_FIELDS});
    } catch (error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)})
    }
};

export const saveResearchFields = (fields) => async dispatch => {
    try{
        dispatch({type: SAVE_RESEARCH_FIELDS, payload: fields});
    } catch (error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)})
    }
};

export const getResearchResults = (data) => async dispatch => {
    try{
        dispatch({type: GET_RESEARCH_RESULTS, payload: 'Ergebnisse werden geladen. Dies kann einen Moment dauern.'});
        const res = await axios.post('/researchService/getAll',data);
        dispatch({type: GET_RESEARCH_RESULTS_SUCCESS, payload: res.data.message});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Ergebnistabelle aktualisiert.'});
    } catch (error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
        dispatch({type: CANCEL_LOADING});
    }
};

export const getResearchResultsV2 = (data) => async dispatch => {
    try{
        dispatch({type: GET_RESEARCH_RESULTS, payload: 'Ergebnisse werden geladen. Dies kann einen Moment dauern.'});
        //console.log('data: ', data);
        const res = await axios.post('/researchService/getAllNew', { lastItem: data.lastKey });
        //console.log('res.data.message: ', res.data.message);
        dispatch({type: GET_RESEARCH_RESULTS_SUCCESS_V2, payload: res.data.message});
        dispatch({type: SET_RESPONSE_MESSAGE, payload: 'Ergebnistabelle aktualisiert.'});
    } catch (error) {
        dispatch({type: SET_ERROR_MESSAGE, payload: parseErrorMessages(error)});
        dispatch({type: CANCEL_LOADING});
    }
};

export const clearInquiry = () => async dispatch => {
    dispatch({type: CLEAR_INQUIRY});
};

export const clearSearchFields = () => async dispatch => {
    dispatch({type: CLEAR_SEARCH_FIELDS});
};

export const saveSearchFields = (fields) => async dispatch => {
    dispatch({type: SAVE_SEARCH_FIELDS, payload: fields});
};
import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import {
  clearErrorMessage,
  clearResponseMessage,
  clearInfoMessage,
  setErrorMessage
} from '../redux/action';
import {
  getTokenExpiringDate,
  getToken
} from '../constants/helpers';
import {
  notificationOptions, responseTranslator
} from '../constants/defaultValues';

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../views/error/Page404";

import ScrollToTop from "../components/ScrollToTop";

import { authorizedAdminUsers } from '../constants/defaultValues';

const ViewMain = React.lazy(() => 
  import('./../views')
);
const ViewOverview = React.lazy(() => 
  import('./../views/overview')
);
const ViewInquiry = React.lazy(() => 
  import('./../views/inquiry')
);
const ViewResearch = React.lazy(() => 
  import('./../views/research')
);
const ViewUser = React.lazy(() => 
  import('./../views/user')
);
const ViewPricing = React.lazy(() =>
  import('./../views/pricing')
);
const ViewAdmin = React.lazy(() =>
  import('./../views/admin')
);

const SimpleRoute = ({component: Component, Layout, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => 
        <Suspense fallback={<div className='loading'/>}>
          <Layout>
            <Component {...props}/>
          </Layout>
        </Suspense>
      }
    />
  )
}

const AuthRoute = ({component: Component, Layout, enterFunction, ...rest}) => {
  return (
    <Route 
      {...rest}
      render={props => 
        enterFunction() ? (
          <Suspense fallback={<div className='loading'/>}>
            <Layout>
              <Component {...props}/>
            </Layout>
          </Suspense>
        ) : (
          <Redirect
            to={{
              pathname: '/user',
              state: {from: props.location}
            }}
          />
        )
      }
    />
  );
}

class Routes extends Component{
  constructor(props){
    super(props);
    this.state = {
      errorMessage: '',
      responseMessage: '',
      infoMessage: ''
    };
    this.isLoggedIn = this.isLoggedIn.bind(this)
    this.isLoggedInAdmin = this.isLoggedInAdmin.bind(this)
  }

  isLoggedIn(){
    const now = Date.now();
    if (this.props.loggedIn && this.props.loggedIn === 'loggedIn' && getToken() && now < getTokenExpiringDate())
      return true;
    if (!this.props.loggedIn)
      this.props.setErrorMessage(responseTranslator['Unauthorized']['text']);
    return false;
  }

  isLoggedInAdmin(){
    return this.isLoggedIn() && this.props.customerId && authorizedAdminUsers.includes(this.props.customerId);
  };

  componentDidUpdate(){
    this.checkForChangeAndNotify();
  }

  checkForChangeAndNotify(){
    const {errorMessage,responseMessage,infoMessage} = this.props;
    if (errorMessage !== this.state.errorMessage){
      this.setState({errorMessage: errorMessage});
      if (errorMessage !== '') {
        const errorToastr = toastr.error;
        errorToastr(
          '',
          errorMessage,
          notificationOptions
        );
        setTimeout(()=>this.props.clearErrorMessage(),1000);
      }
    }
    if (responseMessage !== this.state.responseMessage){
      this.setState({responseMessage: responseMessage});
      if (responseMessage !== ''){
        const successToastr = toastr.success;
        successToastr(
          '',
          responseMessage,
          notificationOptions
        );
        setTimeout(()=>this.props.clearResponseMessage(),1000);
      }
    }
    if (infoMessage !== this.state.infoMessage){
      this.setState({infoMessage: infoMessage});
      if (infoMessage !== ''){
        const infoToastr = toastr.info;
        infoToastr(
          'Info',
          infoMessage,
          notificationOptions
        );
      }
    }
  }

  render(){
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <SimpleRoute
              path='/'
              exact
              Layout={DashboardLayout}
              component={ViewMain}
            />
            <SimpleRoute
                path='/user'
                Layout={AuthLayout}
                component={ViewUser}
              />
            <AuthRoute
                path='/overview'
                exact
                Layout={DashboardLayout}
                component={ViewOverview}
                enterFunction={this.isLoggedIn}
            />
            <AuthRoute
                path='/inquiry'
                exact
                Layout={DashboardLayout}
                component={ViewInquiry}
                enterFunction={this.isLoggedIn}
            />
            <AuthRoute
                path='/research'
                Layout={DashboardLayout}
                component={ViewResearch}
                enterFunction={this.isLoggedIn}
            />
            <AuthRoute
              path='/pricing'
              Layout={DashboardLayout}
              component={ViewPricing}
              enterFunction={this.isLoggedIn}
            />
            <AuthRoute
              path='/admin'
              Layout={DashboardLayout}
              component={ViewAdmin}
              enterFunction={this.isLoggedInAdmin}
            />
            <SimpleRoute
              path='/error'
              Layout={AuthLayout}
              component={Page404}
            />
            <Redirect to='/error'/>
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = ({common}) => {
  const {errorMessage,responseMessage,infoMessage,loggedIn,customerId} = common;
  return {errorMessage,responseMessage,infoMessage,loggedIn,customerId};
}

export default connect(
  mapStateToProps,
  {clearErrorMessage,clearResponseMessage,clearInfoMessage,setErrorMessage}
)(Routes);

import {
    CANCEL_LOADING,
    INQUIRY,
    INQUIRY_SUCCESS,
    SAVE_SEARCH_FIELDS,
    CLEAR_SEARCH_FIELDS,
    CLEAR_INQUIRY,
    GET_RESEARCH_RESULTS_SUCCESS,
    GET_RESEARCH_RESULTS_SUCCESS_V2,
    GET_RESEARCH_RESULTS,
    CLEAR_RESEARCH_RESULTS,
    DELETE_ENTRY_2_SUCCESS,
    DELETE_ENTRY_2_ERROR,
    RESET_RESEARCH_RESULTS,
    SAVE_RESEARCH_FIELDS,
    CLEAR_RESEARCH_FIELDS
} from '../action';

const INIT_STATE = {
    inquriyResult: null,
    loading: false,
    searchFields: {
        id:'',
        surname:'',
        lastname:'',
        street: '',
        zip:'',
        city:'',
        dataSource:'acx',
        searchType:'both',
        legal: false
    },
    researchFields: {
        vorgangsbezeichnung: '',
        title: '',
        name: '',
        firm1: '',
        firm2: '',
        firm3: '',
        firstname: '',
        nameappend: '',
        street: '',
        zip: '',
        city: '',
        adressappend: '',
        mailbox: '',
        district: '',
        country: 'Deutschland',
        phone: '',
        dob: '',
        oldName: '',
        purpose: [],
        followUp: '1',
        acceptTerms: false
    },
    numLoads: 0,
    researchResults: null,
    researchResultsV2: [],
    lastKey: null
};

/*const INIT_STATE = {
    inquriyResult: null,
    loading: false,
    searchFields: {
        id:'Testing',
        surname:'Jürgen',
        lastname:'Lins',
        street: 'Sinntalstrass 2a',
        zip:'36391',
        city:'Oberzell',
        dataSource:'acx',
        searchType:'both',
        legal: false
    },
    researchResults: null,
    researchResultsV2: [],
    lastKey: null
};*/

/*const INIT_STATE = {
    inquriyResult: null,
    loading: false,
    searchFields: {
        id:'test abc',
        surname:'Sebastian',
        lastname:'Krämer',
        street: 'Adolfstr. 1',
        zip:'65582',
        city:'Diez',
        dataSource:'acx',
        searchType:'both',
        legal: false
    },
    numLoads: 0,
    researchResults: null,
    researchResultsV2: [],
    lastKey: null
};*/

const requests = (state = INIT_STATE, action) => {
    switch(action.type){
        case CANCEL_LOADING:
            return {...state, loading: false};
        case INQUIRY:
            return {...state, loading: true};
        case INQUIRY_SUCCESS:
            return {...state, loading: false, inquriyResult: action.payload};
        case SAVE_SEARCH_FIELDS:
            return {...state, searchFields: {
                id: action.payload.clientRef,
                surname: action.payload.firstName,
                lastname: action.payload.lastName,
                street: action.payload.addressLine,
                zip: action.payload.postalCode,
                city: action.payload.locality,
                dataSource: action.payload.dataSource,
                searchType: action.payload.searchType,
                legal: false,
                ...action.payload
            }};
        case CLEAR_INQUIRY:
            return {...state, inquriyResult: INIT_STATE.inquriyResult};
        case CLEAR_SEARCH_FIELDS:
            return {...state, searchFields: INIT_STATE.searchFields};
        case GET_RESEARCH_RESULTS:
            const numLoads2 = state.numLoads + 1;
            return {...state, loading: true, numLoads: numLoads2};
        case GET_RESEARCH_RESULTS_SUCCESS:
            return {...state, researchResults: action.payload};
        case CLEAR_RESEARCH_RESULTS:
            return {...state, researchResults: INIT_STATE.researchResults, lastKey: INIT_STATE.lastKey, researchResultsV2: INIT_STATE.researchResultsV2};
        case GET_RESEARCH_RESULTS_SUCCESS_V2:
            return {...state, researchResultsV2: state.researchResultsV2.concat(action.payload.Items), lastKey: action.payload.LastEvaluatedKey, loading: false};
        case DELETE_ENTRY_2_SUCCESS:
            const newResults_2 = state.researchResultsV2.filter(entry=>entry.createdAt!==action.to_del.createdAt);
            return {...state, researchResultsV2: newResults_2};
        case DELETE_ENTRY_2_ERROR:
            return {...state, researchResultsV2: INIT_STATE.researchResultsV2};
        case RESET_RESEARCH_RESULTS:
            return {...state, researchResultsV2: INIT_STATE.researchResultsV2, numLoads: INIT_STATE.numLoads};
        case SAVE_RESEARCH_FIELDS:
            return {...state, researchFields: {...action.payload, acceptTerms: false}};
        case CLEAR_RESEARCH_FIELDS:
            return {...state, researchFields: INIT_STATE.researchFields};
        default:
            return {...state};
    }
};

export default requests;
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { getContract, toggleQuestion } from '../redux/common/actions';
import { Badge, Button } from "reactstrap";
import {ReactComponent as LogoIcon} from '../assets/img/new_logo_202202.svg';
import { authorizedAdminUsers } from "../constants/defaultValues";

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, Icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? (
            <React.Fragment>
              <Icon className="align-middle mr-2" style={{height: 20, width: 20}}/>
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends Component{

  constructor(props){
    super(props);
    this.state = {
      routeOpen: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      routeOpen: !prevState.routeOpen
    }));
  }

  render(){
    const {isSidebarOpen,customerId} = this.props;
    return (
        <nav
          className={classNames(
            "sidebar",
            isSidebarOpen || "toggled"
          )}>
          <div className='sidebar-content'>
            <LogoIcon className={classNames("sidebar-brand")}/>
            <ul className='sidebar-nav'>
              <SidebarItem
                name='Adressermittlung / Umzugsauskunft'
                to='/inquiry'/>
              {/*<SidebarItem
                name='Adress-Recherchen'
                to='/research/research'/>*/}
              <SidebarItem
                name='Adress-Recherchen'
                to='/research/researchV2'/>
              {/*<SidebarItem
                name='Recherche-Ergebnisse'
                to='/research/results'/>*/}
              <SidebarItem
                name='Recherche-Ergebnisse'
                to='/research/resultsV2'/>
              <SidebarItem
                name='Einzelnachweise und Mitbenutzerübersicht'
                to='/overview'/>
              {/*<SidebarItem 
                name='Preise ab März 2023'
                to='/pricing'
                />*/}
              {authorizedAdminUsers.includes(customerId)?<SidebarItem
                name='Admin'
                to='/admin'/>:''}
            </ul>
            <ul className="mb-4 sidebar-nav justify-content-end">
              <li className='mt-2' style={{display: 'flex', justifyContent: 'center'}}>
                <Button className="mx-1" color='primary' onClick={()=>this.props.getContract(1)}>AV Vertrag</Button>
                <Button className="mx-1" color='primary' onClick={()=>this.props.getContract(2)}>AVV - Anlage 2</Button>
              </li>
              <li className='mt-2' style={{display: 'flex', justifyContent: 'center'}}><Button color='secondary' onClick={()=>this.props.toggleQuestion()}>Sie haben Fragen?</Button></li>
            </ul>
          </div>
        </nav>
    );
  }
}

const mapStateToProps = ({common}) => {
  const {sidebar, isSidebarOpen, customerId} = common;
  return {sidebar, isSidebarOpen, customerId};
}

export default withRouter(
  connect(mapStateToProps,
    {toggleQuestion, getContract}
    )(Sidebar)
);
